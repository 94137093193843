import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import MyBetsImg from '../../assets/images/common/bets.svg';
import MyWalletImg1 from '../../assets/images/common/wallet.svg';
import AccStmtImg from '../../assets/images/common/acc.svg';
import PLStmtImg from '../../assets/images/common/acc.svg';
import LogoutImg from '../../assets/images/common/logout.svg';
import profile from '../../assets/images/common/gg_profile.svg';
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import './Dashboard.scss';
import { NavLink } from 'react-router-dom';
import { Backdrop, Button } from '@material-ui/core';
import Modal from '../Modal/Modal';
import ChangePassword from '../ChangePassword/ChangePassword';
import { getCurrencyTypeFromToken, logout } from '../../store';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import keyIcon from '../../assets/images/common/key.svg';
import InputImg from '../../assets/images/common/input.svg';
import Click from '../../assets/images/footer/theme.svg';
import { demoUserPrefix, demoUsers } from '../../util/stringUtil';

type StoreProps = {
  balance: number;
  allowedConfig: number;
  commissionEnabled: boolean;
  logout: () => void;
  exposure: number;
  setShowPopover?: any;
};

const DashboardView: React.FC<StoreProps> = (props) => {
  const {
    balance,
    allowedConfig,
    commissionEnabled,
    logout,
    exposure,
    setShowPopover,
  } = props;
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const dashboardItems = [
    {
      key: 'edit-profile',
      name: 'My Profile',
      imgSrc: profile,
      url: '/profile',
      class: '',
    },
    {
      key: 'my-bets',
      name: 'My Bets',
      imgSrc: MyBetsImg,
      url: '/my_bets',
      class: '',
    },
    // {
    //   key: 'my-wallet',
    //   name: 'My Wallet',
    //   imgSrc: MyWalletImg1,
    //   url: '/my_wallet',
    //   class: '',
    // },
    {
      key: 'acc-stmt',
      name: 'Account Statement',
      imgSrc: AccStmtImg,
      url: '/account_statement',
      class: '',
    },
    {
      key: 'game-report',
      name: 'Game Report',
      imgSrc: AccStmtImg,
      url: '/game_report',
      class: '',
    },
    {
      key: 'pl-stmt',
      name: 'P/L Statement',
      imgSrc: PLStmtImg,
      url: '/pl_statement',
      class: '',
    },
    {
      key: 'set-btn-vars',
      name: 'Set Button Variables',
      imgSrc: InputImg,
      url: '/set-button-variables',
      class: '',
    },
    {
      key: 'Change Password',
      name: 'Change Password',
      imgSrc: keyIcon,
      url: '/change-password',
      class: '',
    },
    {
      key: 'One Click',
      name: 'One Click Betting',
      imgSrc: Click,
      url: '/mo/oneclick',
      class: 'mob-view',
    },
  ];

  return (
    <>
      {' '}
      <div className="reports-ctn my-wallet-ctn mob-view">
        <div className="carw mob-view">
          <div className="arrow arrow-right">
            {' '}
            <div className="name">{sessionStorage.getItem('username')}</div>
          </div>
        </div>
        <div className="header-content mob-view">
          <div className="user-balance">
            Bal: {Number(Number(balance / cFactor).toFixed(2)).toLocaleString('en-US')}
          </div>
          <div className="user-balance">
            Exp:{' '}
            {exposure !== null ? Number(Number(exposure / cFactor).toFixed(2)).toLocaleString('en-US') : '-.--'}
          </div>
        </div>
        <div className="dashboard-ctn">
          <IonRow className="dashboard-cards-ctn">
            {dashboardItems.map((item) => (
              <>
                {item.url === '/change-password' ? (
                  <>
                    {(sessionStorage.getItem('username') &&
                      demoUsers.includes(
                        sessionStorage.getItem('username').toLowerCase()
                      )) ||
                    sessionStorage
                      .getItem('username')
                      ?.startsWith(demoUserPrefix) ? null : (
                      <IonCol
                        className="dashboard-item"
                        key={item.key}
                        sizeXs="12"
                        sizeSm="12"
                        sizeMd="12"
                      >
                        <NavLink to={item.url} className="dashboard-item-link">
                          <div className="ion-ctn">
                            <IonIcon
                              className={
                                item.key === 'game-report'
                                  ? 'dashboard-item-icon sports-icon'
                                  : 'dashboard-item-icon'
                              }
                              color="primary"
                              src={item.imgSrc}
                            />
                          </div>
                          <span className="dashboard-item-text">
                            {item.name}
                          </span>
                        </NavLink>
                      </IonCol>
                    )}
                  </>
                ) : (
                  <IonCol
                    className="dashboard-item"
                    key={item.key}
                    sizeXs="12"
                    sizeSm="12"
                    sizeMd="12"
                  >
                    <NavLink to={item.url} className="dashboard-item-link">
                      <div className="ion-ctn">
                        <IonIcon
                          className={
                            item.key === 'game-report'
                              ? 'dashboard-item-icon sports-icon'
                              : 'dashboard-item-icon'
                          }
                          color="primary"
                          src={item.imgSrc}
                        />
                      </div>
                      <span className="dashboard-item-text">{item.name}</span>
                    </NavLink>
                  </IonCol>
                )}
              </>
            ))}

            {/* <IonCol
              sizeXs="12"
              sizeSm="12"
              sizeMd="12"
              className="dashboard-item change-pwd-col"
            >
              <NavLink
                to={'/commission_report'}
                className="dashboard-item-link"
              >
                <IonIcon
                  className="dashboard-item-icon"
                  color="primary"
                  src={MyWalletImg}

                />
                <span className="dashboard-item-text">Commission Report</span>
              </NavLink>
            </IonCol> */}
          </IonRow>

          <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
            <Modal
              open={changePwdMOdal}
              closeHandler={() => setChangePwdMOdal(false)}
              title="Change Password"
              size="xs"
            >
              <ChangePassword
                closeHandler={() => setChangePwdMOdal(false)}
                backHandler={() => {}}
              />
            </Modal>
          </Backdrop>
        </div>
        <div className="logout-item-row">
          <Button onClick={() => logout()} className="btn">
            <IonIcon color="primary" src={LogoutImg} className="icon" />
            Sign Out
          </Button>
        </div>{' '}
      </div>
      <div className="web-dropdown web-view">
        <div className="title">Account Settings</div>
        <div className="dashboard-ctn">
          <IonRow className="dashboard-cards-ctn">
            {dashboardItems
              ?.filter((item) => item.key !== 'One Click')
              .map((item) => (
                <>
                  {item.url === '/change-password' ? (
                    <>
                      {(sessionStorage.getItem('username') &&
                        demoUsers.includes(
                          sessionStorage.getItem('username').toLowerCase()
                        )) ||
                      sessionStorage
                        .getItem('username')
                        ?.startsWith(demoUserPrefix) ? null : (
                        <IonCol
                          className="dashboard-item"
                          key={item.key}
                          sizeXs="12"
                          sizeSm="12"
                          sizeMd="12"
                        >
                          <NavLink
                            to={item.url}
                            className="dashboard-item-link"
                            onClick={() =>
                              setShowPopover({ open: false, event: undefined })
                            }
                          >
                            <div className="ion-ctn">
                              <IonIcon
                                className={
                                  item.key === 'game-report'
                                    ? 'dashboard-item-icon sports-icon'
                                    : 'dashboard-item-icon'
                                }
                                color="primary"
                                src={item.imgSrc}
                              />
                            </div>
                            <span className="dashboard-item-text">
                              {item.name}
                            </span>
                          </NavLink>
                        </IonCol>
                      )}
                    </>
                  ) : (
                    <IonCol
                      className="dashboard-item"
                      key={item.key}
                      sizeXs="12"
                      sizeSm="12"
                      sizeMd="12"
                    >
                      <NavLink
                        to={item.url}
                        className="dashboard-item-link"
                        onClick={() =>
                          setShowPopover({ open: false, event: undefined })
                        }
                      >
                        <div className="ion-ctn">
                          <IonIcon
                            className={
                              item.key === 'game-report'
                                ? 'dashboard-item-icon sports-icon'
                                : 'dashboard-item-icon'
                            }
                            color="primary"
                            src={item.imgSrc}
                          />
                        </div>
                        <span className="dashboard-item-text">{item.name}</span>
                      </NavLink>
                    </IonCol>
                  )}
                </>
              ))}
          </IonRow>

          <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
            <Modal
              open={changePwdMOdal}
              closeHandler={() => setChangePwdMOdal(false)}
              title="Change Password"
              size="xs"
            >
              <ChangePassword
                closeHandler={() => setChangePwdMOdal(false)}
                backHandler={() => {}}
              />
            </Modal>
          </Backdrop>
        </div>

        <div className="logout-item-row">
          <Button onClick={() => logout()} className="btn">
            <IonIcon color="primary" src={LogoutImg} className="icon" />
            Sign Out
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    allowedConfig: state.common.allowedConfig,
    commissionEnabled: state.common.commissionEnabled,
    exposure: state.auth.balanceSummary.exposure,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
