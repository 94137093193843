import { format } from 'date-fns';
import JSEncrypt from 'jsencrypt';
import { RsaPublicKey } from '../constants/RSAPublicKey';
import diamond_logo from '../assets/images/common/diamond.svg';
import lotus_logo from '../assets/images/common/lotus.svg';
import sky_exch_logo from '../assets/images/common/skyexch.svg';

export function formatDate(date: Date) {
  try {
    return format(date, 'dd/MM/yy hh:mm a');
  } catch (err) {
    return date;
  }
}

export function formatTime(date: Date) {
  try {
    return format(date, 'HH:mm');
  } catch (err) {
    return date;
  }
}

export const SportIdByNameMap: { [key: string]: string } = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  basketball: '7522',
  baseball: '7511',
  greyhoundracing: '4339',
  horseracing: '7',
};

export const getLogo = (skin) => {
  switch (skin.toLowerCase()) {
    case 'diamond':
      return diamond_logo;
    case 'lotus':
      return lotus_logo;
    default:
      return sky_exch_logo;
  }
};

export const SportNameByIdMap: { [key: string]: string } = {
  '1': 'soccer',
  '2': 'tennis',
  '4': 'cricket',
  '7522': 'basketball',
  '7511': 'baseball',
  '4339': 'greyhoundracing',
  '7': 'horseracing',
};

export const OutcomeDescMap = {
  '0': 'Lost',
  '1': 'Win',
  '2': 'Unsettled',
  '3': 'Rolled Back',
  '4': 'Void',
  '5': 'Lapsed',
  '6': 'Cancelled',
  '7': 'Expired',
  '8': 'Pending',
  '9': 'Unmatched',
  '10': 'Timely Void',
  '11': 'Alert Void',
};

export const OutcomeDescByEnumMap = {
  Lost: 'Lost',
  Won: 'Win',
  Open: 'Unsettled',
  '11': 'Unsettled',
  RolledBack: 'Rolled Back',
  Voided: 'Void',
  Lapse: 'Lapsed',
  Cancelled: 'Cancelled',
};

export const IndianCasinoMarketNameMap: { [key: string]: string } = {
  '0': 'Lucky 7B',
  '1': 'Lucky 7A',
  '2': 'Teenpatti',
  '3': 'Dragon Tiger 2020',
  '4': 'Dragon Tiger Lion',
  '5': 'Bollywood Casino AAA',
  '6': 'Race 2020',
  '7': '32 Card',
  '8': '3Card Judgement',
  '9': 'Andar Bahar',
};

export const MarketTypeMap: { [key: string]: string } = {
  '0': 'Match odds',
  '1': 'Book maker',
  '2': 'Fancy',
  '3': 'Premium',
  '4': 'Casino',
  '5': 'Binary',
  '6': 'Indian Casino',
};

export const MarketTypeByEnumMap: { [key: string]: string } = {
  MATCH_ODDS: 'Match odds',
  BOOKMAKER: 'Book maker',
  FANCY: 'Fancy',
  PREMIUM: 'Premium',
  CASINO: 'Casino',
  BINARY: 'Binary',
  INDIAN_CASINO: 'Indian Casino',
};

export const TransactionTypeMap = {
  '0': 'Deposit',
  '1': 'Withdraw',
  '2': 'Deposit',
  '3': 'Withdraw',
  '4': 'Bet Placement',
  '5': 'Bet Settlement',
  '6': 'Rollback Bet Settlement',
  '7': 'Void Bet Settlement',
  '8': 'Casino Bet Placement',
  '9': 'Casino Bet Settlement',
  '10': 'Casino Bet Rollback',
  '11': 'Win Commission',
  '12': 'Win Commission Reversal',
  '13': 'Indian Casino Bet Placement',
  '14': 'Indian Casino Bet Settlement',
  '15': 'Commission Claim',
  '16': 'Commission Deposit',
  '17': 'Commission Rollback',
  '18': 'Commission Void',
  '19': 'Casino Bet Placement',
  '20': 'Casino Bet Settlement',
  '21': 'Casino Bet Rollback',
  '22': '-',
  '23': 'Binary Bet Placement',
  '24': 'Binary Bet Settlement',
  '25': 'Binary Bet Rollback',
  '26': 'Bet Placement',
  '27': 'Bet Settlement',
  '28': 'Bet Rollback',
  '29': 'Bet Void',
  '30': 'Bet Cancelled',
  '31': 'Bet Expired',
  '32': 'Bet Lapsed',
  '33': 'Bet Deleted',
  '34': 'Risk Update',
  '35': 'Binary Bet Void',
  '36': 'Sport Book Bet Placement',
  '37': 'Sport Book Bet Settlement',
  '38': 'Sport Book Bet Rollback',
  '39': 'Rolling Commission',
  '40': 'Win Commission Update',
  '41': 'Win Commission Update',
  '42': 'Void Rollback',
};

export function getTransactionNameByID(trans: string) {
  return TransactionTypeMap[trans];
}

export function getOutcomeDescName(eventType: string) {
  return OutcomeDescMap[eventType];
}

export function getSportIdByName(eventType: string) {
  return SportIdByNameMap[eventType];
}

export function getSportNameByIdMap(eventType: string) {
  return SportNameByIdMap[eventType];
}

export function MarketTypeByID(id: string) {
  return MarketTypeMap[id] ? MarketTypeMap[id] : 'Casino';
}

export function DisabledBinaryEvents() {
  return ['NIFTY BANK', 'NIFTY 50'];
}

export const getBetTimeoutByMarket: { [key: string]: number } = {
  FANCY: 1,
  MO: 1,
  BM: 1,
  BINARY: 1,
  CASINO: 1,
  OT: 1,
};

export const marketIdByNameMap = {
  2: 'FANCY',
  0: 'MO',
  1: 'BM',
  3: 'OT',
};

export const demoUsers = [
  'demo01',
  'demo02',
  'demo03',
  'demo04',
  'demo05',
  'demo06',
  'demo07',
  'demo08',
  'demo09',
  'demo010',
  'stardemo',
  'skyxdemo',
  'playdemo',
  'dubdemo',
  'betdemo',
  'bigdemo',
  'titodemo',
  'maxdemo',
  'cbtfdemo',
  'fairdemo',
  'thundersdemo',
  'playbhaidemo',
  'playexchdemo',
  'playbetdemo',
  'indian11demo',
  'corbet247demo',
  'cherrysky11demo',
  '786skydemo',
  'uniexchdemo',
  'dafabet11demo',
  'baazifairdemo',
  'sarkar11demo',
  'krishna11demo',
  'demo001',
  'demo002',
  'betfairclubdemo',
  'marvel11demo',
  'windaddydemo',
  'earthdemo',
  'sidjadudemo',
  'rock8demo'
];

export const _findIndexByArray = (array: any, value: any) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === value) {
      return i;
    }
  }
};

export const SportMarketMap: { [key: string]: string } = {
  'MATCH ODDS': 'MT0.0',
  'OVER/UNDER 0.5 GOALS': 'MT0.5',
  'OVER/UNDER 1.5 GOALS': 'MT1.5',
  'OVER/UNDER 2.5 GOALS': 'MT2.5',
};

export const getEncreptedPwd = (password) => {
  let RSAEncrypt = new JSEncrypt({ default_key_size: '1024' });
  RSAEncrypt.setPublicKey(RsaPublicKey);
  return RSAEncrypt.encrypt(password);
};

export const SPToBFIdMap = {
  'sr:sport:1': '1',
  'sr:sport:5': '2',
  'sr:sport:21': '4',
};
export const BFToSRIdMap = {
  '1': 'sr:sport:1',
  '2': 'sr:sport:5',
  '4': 'sr:sport:21',
  "7522": "sr:sport:2",
  "7511": "sr:sport:3",
};
export const oddValueFormatter = (marketType, oddValue, sessionRuns) => {
  if (oddValue === -1) {
    return '-';
  }

  let res = '-';
  switch (marketType) {
    case 'BOOKMAKER':
    case 1:
      res = Number(oddValue * 100 - 100).toFixed(2);
      break;
    case 'FANCY':
    case 2: // fancy
      res =
        Number(sessionRuns).toFixed(0) +
        '/' +
        Number(oddValue * 100 - 100).toFixed(0);
      break;
    case 'BINARY':
    case 5: // binary
      res = Number(sessionRuns).toFixed(0);
      break;
    default:
      res = Number(oddValue).toFixed(2);
  }

  return res;
};

export const outcomeNameFormatter = (
  marketType,
  marketName,
  oddValue,
  outcomeDesc,
  sessionRuns
) => {
  let res = '-';
  switch (marketType) {
    case 'FANCY':
      res = marketName + ' @ ' + Number(oddValue * 100 - 100).toFixed(0);
      break;
    case 'BINARY':
      res = outcomeDesc + ' @ ' + Number(sessionRuns).toFixed(0);
      break;
    default:
      res = outcomeDesc;
  }

  return res;
};

export const demoUserPrefix = "zzzzsysdemo";

export const capitalize = (str: string) => {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    if(words[i].trim()){
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
  }

  return words.join(" ");
}

export enum Jwt_token {
  ACCOUNT_ID = 'aid',
  HOUSE_ID = 'hid',
  SAP_TOKEN = 'sap',
  CURRENCY_TYPE = 'cur',
  PERMISSION = 'perm',
  ROLE = 'role',
  SUBJECT_NAME = 'sub',
  OWNER_NAME = 'owner',
  ACCOUNT_PATH = 'path',
  AFFILIATE_PATH = 'affPath',
}